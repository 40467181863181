import React, { FC, ReactNode } from "react"
import { useComponentMounted }  from "../../hooks/useComponentMounted"

interface Props {
  // Any content that will be displayed on first page load;
  // rendered out statically by Gatsby
  staticContent?: ReactNode,
}

const defaultProps: Props = {
  staticContent: null
}

/* Component that allows dynamic rendering once it's been loaded into the DOM.
* Allows static content to be rendered by Gatsby when it's not ready / first
* render of page, then uses React's hydration process to take over. */
const MountingBracket: FC<Props> = ( props ) => {
  
  const hasMounted = useComponentMounted()
  
  // If no React mount occurred during an initial useEffect, show static content
  if( !hasMounted ) return <>{ props.staticContent }</>
  
  // Otherwise, hydration occurred so show dynamic content
  return <>{ props.children }</>
}

MountingBracket.defaultProps = defaultProps

export { MountingBracket }
