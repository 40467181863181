import React, { FC, useState } from "react"
import { ScrollObserver }      from "../../common/site/ScrollObserver"

interface Props {}

const defaultProps: Props = {}

const PortalsAnimation: FC<Props> = ( props ) => {
  
  return (
    
    <ScrollObserver elementId={ "portals-animation" } options={ {
      onScrollIntersectClassname: "activated",
      rootMargin:                 "0px 0px -15% 0px"
    } }>
      <div className={ "animation-container scale-75 md:scale-100" }>
        <div className={ "square-a" } />
        <div className={ "square-b" } />
        <div className={ "square-c" } />
        <div className={ "square-d" } />
      </div>
    </ScrollObserver>
  )
}

PortalsAnimation.defaultProps = defaultProps

export { PortalsAnimation }
