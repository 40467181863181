import React, { FC, useState } from "react"
import { BlockText }           from "../../common/type/BlockText"
import { ClearStackBlock }     from "../../common/site/StackBlock"
import { PortalsAnimation }    from "./PortalsAnimation"

interface Props {}

const defaultProps: Props = {}

const PortalsContentBlock: FC<Props> = ( props ) => {
  
  return (
    
    <ClearStackBlock>
      
      <div className={ `grid grid-cols-2 md:grid-cols-[33%_1fr]` }>
        <div>
          <h2 className={ `text-xl sm:text-2xl text-green-600` }>
            <BlockText><span className={ `text-slate-100 text-2xl sm:text-3xl` }>Portals</span> that </BlockText>
            <BlockText>that connect <span className={ `text-2xl sm:text-3xl text-green-500` }>patients</span> with <span className={ `text-2xl sm:text-3xl text-green-500` }>life-saving treatments</span>.</BlockText>
          </h2>
        </div>
        <div>
          
          <PortalsAnimation />
        
        </div>
      </div>
    
    </ClearStackBlock>
  )
}

PortalsContentBlock.defaultProps = defaultProps

export { PortalsContentBlock }
