import { v4 as uuid }                                                         from "uuid"
import React, { FC, ReactNode, useEffect, useLayoutEffect, useRef, useState } from "react"
import { ScrollTriggerOptions, useScrollObserver }                            from "../../../hooks/useScrollObserver"
import { MountingBracket }                                                    from "../MountingBracket"
import { useComponentMounted }                                                from "../../../hooks/useComponentMounted"

interface Props {
  elementId?: string,
  options?: ScrollTriggerOptions,
  staticContent?: ReactNode,
}

const defaultProps: Props = {
  elementId:     uuid(),
  options:       {},
  staticContent: undefined
}

const ScrollObserver: FC<Props> = ( props ) => {
  
  let hasMounted        = useComponentMounted(),
      { scrollTrigger } = useScrollObserver(),
      elementRef        = useRef()
  
  useLayoutEffect( () => {
    
    if( hasMounted ){
      
      scrollTrigger( `#${ props.elementId }`, props.options );
      
    }
    
  }, [ hasMounted ] )
  
  return (
    <MountingBracket staticContent={ props.staticContent }>
      
      <div id={ props.elementId } ref={ elementRef }>
        { props.children }
      </div>
    
    </MountingBracket>
  )
}

ScrollObserver.defaultProps = defaultProps

export { ScrollObserver }
