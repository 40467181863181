import React, { FC }                                   from "react"
import { Cell, Grid }                                  from "styled-css-grid"
import { PageMeta }                                    from "../components/common/site/PageMeta"
import { PageLayout }                                  from "../components/common/site/PageLayout"
import { MountingBracket }                             from "../components/common/MountingBracket"
import { PortalsContentBlock }                         from "../components/page/index/PortalsContentBlock"
import { PlatformsContentBlock }                       from "../components/page/index/PlatformsContentBlock"
import { DataVisualizationContentBlock }               from "../components/page/index/DataVisualizationContentBlock"
import { ProjectManagementContentBlock }               from "../components/page/index/ProjectManagementContentBlock"
import { MobileAppsContentBlock }                      from "../components/page/index/MobileAppsContentBlock"
import { IntroContentBlock }                           from "../components/page/index/IntroContentBlock"
import { MissionStatementContentBlock }                from "../components/page/index/MissionStatementContentBlock"
import { ContactContentBlock }                         from "../components/page/index/ContactContentBlock"
import { DecoBlock, DecoBlockScrollObserverContainer } from "../components/common/display/DecoBlock"

/* Page Query -> props.data */

/*export const query = graphql`
    query IndexPageQuery {
        externalData : allFile( filter: { sourceInstanceName: { in: ["content", "data"] } } ) {
            nodes {
                name
                extension
                dir
                modifiedTime
                internal {
                    content
                }
            }
        }
    }
`*/

interface Props {
  data?: any,
}

const defaultProps: Props = {}

const IndexPage: FC<Props> = ( props ) => {
  
  return (
    <MountingBracket>
      
      <PageMeta />
      
      <PageLayout>
        
        <Grid className={ `relative max-w-full` } columns={ `1fr` } rowGap={ "0px" } height={ "auto" }>
          
          <DecoBlockScrollObserverContainer id={ "top-deco" }>
            <DecoBlock opacity={ 0.75 } offsetY={ -40 } offsetX={ "calc(-20%)" } width={ 1200 } alignment={ "r" } height={ 60 } color={ "green-500" } />
            <DecoBlock opacity={ 0.75 } offsetY={ -120 } offsetX={ "calc(30%)" } height={ 150 } color={ "slate-800" } animateLong={ false } />
          </DecoBlockScrollObserverContainer>
          
          <Cell className={ `sticky z-50 top-[70px] md:top-[40px]` }>
            
            <MissionStatementContentBlock />
          
          </Cell>
          
          <Cell className={ `md:pt-10` }>
            
            <DecoBlockScrollObserverContainer id={ "intro-decos" } appendedContent={ <IntroContentBlock /> }>
              <DecoBlock opacity={ 1 } offsetY={ 265 } offsetX={ `calc(-50%)` } alignment={ "l" } height={ 200 } color={ "green-500" } />
              <DecoBlock opacity={ 0.15 } offsetY={ 80 } offsetX={ `calc(25%)` } color={ "green-700" } />
            </DecoBlockScrollObserverContainer>
          
          </Cell>
          
          <Cell className={ `pt-[300px] md:pt-40 md:pt-52` }>
            
            <DecoBlockScrollObserverContainer id={ "platforms-deco" } appendedContent={ <PlatformsContentBlock /> } scrollObserverOptions={ {
              //threshold:         [ 0, 0.25, 0.5, 0.75, 1 ],
              rootMargin:        "-15% 0px -50%",
              onScrollIntersect: ( el, unobserveMethod, entry ) => {
                
                const missionStatementEl = document.querySelector( "#mission-statement-text" ) as HTMLDivElement
                
                if( parseFloat( missionStatementEl.dataset.mark ) > entry.boundingClientRect.top ){
                  
                  missionStatementEl.classList.remove( "scaled" )
                  delete missionStatementEl.dataset.mark
                  
                } else {
                  
                  missionStatementEl.classList.add( "scaled" )
                  missionStatementEl.dataset.mark = `${ entry.boundingClientRect.top }`
                }
              }
            } }>
              <DecoBlock opacity={ 0.45 } offsetY={ -75 } offsetX={ "-50%" } width={ 600 } height={ 60 } color={ "obsidian" } />
              <DecoBlock opacity={ 0.15 } offsetY={ 70 } offsetX={ "50%" } width={ 1200 } height={ 150 } color={ "slate-500" } animateLong={ false } />
            </DecoBlockScrollObserverContainer>
          
          </Cell>
          
          <Cell className={ `pt-40 md:pt-52` }>
            
            <DecoBlockScrollObserverContainer id={ "portals-decos" } prependedContent={ <PortalsContentBlock /> }>
              <DecoBlock opacity={ 0.5 } offsetY={ -220 } offsetX={ "calc(-25%)" } height={ 400 } color={ "obsidian" } />
              <DecoBlock opacity={ 0.25 } offsetY={ -150 } offsetX={ "calc(30%)" } height={ 200 } alignment={ "r" } color={ "slate-800" } />
            </DecoBlockScrollObserverContainer>
          
          </Cell>
          
          <Cell className={ `pt-40 md:pt-52` }>
            <DataVisualizationContentBlock />
          </Cell>
          
          <Cell className={ `pt-40 md:pt-52` }>
            
            <DecoBlockScrollObserverContainer id={ "project-management-decos" } appendedContent={ <ProjectManagementContentBlock /> }>
              <DecoBlock opacity={ 0.05 } offsetY={ -80 } offsetX={ "200px" } width={ 1200 } height={ 60 } color={ "slate-500" } />
              <DecoBlock opacity={ 0.5 } offsetY={ 150 } offsetX={ "calc(-30%)" } height={ 150 } color={ "obsidian" } />
              <DecoBlock opacity={ 0.05 } offsetY={ -80 } offsetX={ "calc(50%)" } alignment={ "r" } color={ "green-700" } />
            </DecoBlockScrollObserverContainer>
          
          </Cell>
          
          <Cell className={ `pt-40 md:pt-52` }>
            
            <DecoBlockScrollObserverContainer id={ "contact-bottom-deco" } appendedContent={ <MobileAppsContentBlock /> }>
              <DecoBlock opacity={ 0.5 } offsetY={ 100 } offsetX={ "calc(-50%)" } height={ 120 } color={ "obsidian" } />
              <DecoBlock opacity={ 0.25 } offsetY={ -180 } offsetX={ "calc(20% + 600px)" } width={ 1200 } height={ 50 } alignment={ "r" } color={ "obsidian" } />
              <DecoBlock opacity={ 0.25 } offsetY={ -20 } offsetX={ "calc(25%)" } height={ 75 } alignment={ "r" } color={ "slate-500" } animateLong={ false } />
            </DecoBlockScrollObserverContainer>
          
          
          </Cell>
          
          <Cell className={ `pt-40 md:pt-52` }>
            
            <DecoBlockScrollObserverContainer id={ "contact-deco" } appendedContent={ <ContactContentBlock /> } scrollObserverOptions={ { rootMargin: "0px 0px -50% 0px" } }>
              <DecoBlock opacity={ 0.75 } offsetY={ 240 } offsetX={ "calc(10%)" } width={ 1200 } height={ 100 } color={ "green-500" } />
              <DecoBlock opacity={ 0.5 } offsetY={ 220 } offsetX={ "calc(-30%)" } height={ 250 } alignment={ "r" } color={ "green-700" } animateLong={ false } />
            </DecoBlockScrollObserverContainer>
          
          </Cell>
        
        </Grid>
      
      </PageLayout>
    </MountingBracket>
  )
}

IndexPage.defaultProps = defaultProps

export default IndexPage
