import React, { FC, useState } from "react"
import { BlockText }           from "../../common/type/BlockText"
import { ClearStackBlock }  from "../../common/site/StackBlock"
import { SCROLL_MARKER_ID } from "./MissionStatementContentBlock"

interface Props {}

const defaultProps: Props = {}

const IntroContentBlock: FC<Props> = ( props ) => {
  
  return (
    
    <ClearStackBlock id={ SCROLL_MARKER_ID }>
      
      <p className={ `text-2xl md:text-3xl text-slate-100 relative z-1` }>
        <span className={ `font-thin leading-10` }>
          <BlockText>Since 2006 we've helped </BlockText>
          <BlockText>companies big and small </BlockText>
          <BlockText>reach their goals</BlockText>
        </span>
        <BlockText className={ `font-medium leading-10 mt-4` }>
          <BlockText>by building bespoke </BlockText>
          <BlockText>digital solutions for their </BlockText>
          <BlockText>business challenges:</BlockText>
        </BlockText>
      </p>
    
    </ClearStackBlock>
  )
}

IntroContentBlock.defaultProps = defaultProps

export { IntroContentBlock }
