import React from "react"

function useComponentMounted(){
  
  const [ hasMounted, setHasMounted ] = React.useState( false )
  
  React.useEffect( () => {
    
    setHasMounted( true )
    
  }, [] )
  
  return hasMounted
}

export { useComponentMounted }
