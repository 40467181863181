import React, { FC, useState } from "react"
import { ClearStackBlock }     from "../../common/site/StackBlock"
import { BlockText }           from "../../common/type/BlockText"
import { MailToLink }          from "../../common/site/MailToLink"

interface Props {}

const defaultProps: Props = {}

const ContactContentBlock: FC<Props> = ( props ) => {
  
  return (
    
    <ClearStackBlock>
      
      <p className={ `text-2xl md:text-3xl text-slate-100 font-medium leading-10 mt-4` }>Interested in hearing about how our team of developers, digital strategists, designers, user experience engineers, and content creators can help you turn your project into reality?</p>
      
      <p className={ "text-center pt-10 md:pt-20 pb-5 md:pb-10" }>
        <MailToLink>
          <span className={ "btn btn-primary btn-lg btn-wide" }>Get in touch.</span>
        </MailToLink>
      </p>
      
      <p className={ "text-center text-2xl md:text-3xl text-slate-100 font-medium leading-10" }>(215) 669-0068</p>
      
      <div className={ "text-center py-5 md:py-10 text-lg text-slate-400 font-medium font-normal" }>
        <address className={ "not-italic" }>
          <BlockText>50 South 16th Street</BlockText> <BlockText>Suite 1700</BlockText> <BlockText>Philadelphia, PA 19102</BlockText>
        </address>
      </div>
    
    
    </ClearStackBlock>
  )
}

ContactContentBlock.defaultProps = defaultProps

export { ContactContentBlock }
