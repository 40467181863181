let defaultColors = require( "tailwindcss/colors" )
let defaultTheme  = require( "tailwindcss/defaultTheme" )

// Deprecated colors
// Prevents "As of Tailwind CSS vx.x, `<COLOR_NAME>` has been renamed to `<NEW_COLOR_NAME>`." warnings.
delete defaultColors.lightBlue
delete defaultColors.warmGray
delete defaultColors.trueGray
delete defaultColors.coolGray
delete defaultColors.blueGray

let config = {
  mode:     "jit",
  content:  [
    "./src/**/*.{js,jsx,ts,tsx}"
  ],
  safelist: [
    "bg-obsidian",
    "bg-slate-500",
    "bg-slate-800",
    "bg-green-500",
    "bg-green-700"
  ],
  theme:    {
    extend: {
      colors:     {
        slate:                 {
          50:  "#F8FAFB",
          100: "#EDF2F5",
          200: "#E1E9F0",
          300: "#B9C5CF",
          400: "#8F9FAD",
          500: "#64798A", // Brand Slate (Light)
          600: "#51626F",
          700: "#3D4954",
          800: "#29323A", // Brand Slate (Dark)
          900: "#181D21"  // Brand Slate (Darker)
        },
        amber:                 {
          50:  "#FFF9E5",
          100: "#FFF0C1",
          200: "#FFE69B",
          300: "#FFDD74",
          400: "#FFD34D", // Brand Orange (Light)
          500: "#FCBD40",
          600: "#F9A532", // Brand Orange (Mid tone)
          700: "#CD8421",
          800: "#A55F05", // Brand Orange (Dark)
          900: "#7D4804"
        },
        green:                 {
          50:  "#EFFFE5",
          100: "#DEFFCA",
          200: "#CFFFB3",
          300: "#AEE58C",
          400: "#82C559",
          500: "#6AB33E", // Brand Green (Light)
          600: "#539937",
          700: "#09783B", // Brand Green (Dark)
          800: "#075429",
          900: "#064823"
        },
        slateGlass:            "rgb(24 29 33 / 0.98)",
        slateGlassTransparent: "rgb(24 29 33 / 0)",
        obsidian:              "#121517"
      },
      fontFamily: {
        sans: [ "Outfit", ...defaultTheme.fontFamily.sans ]
      }
    }
  },
  daisyui:  {
    themes: [
      {
        "byrdalley": {                     /* your theme name */
          "primary":         "#F9A532",    /* Primary color */
          "primary-focus":   "#CD8421",    /* Primary color - focused */
          "primary-content": "#FFFFFF",    /* Foreground content color to use on primary color */
          
          "secondary":         "#8F9FAD",  /* Secondary color */
          "secondary-focus":   "#64798A",  /* Secondary color - focused */
          "secondary-content": "#FFFFFF",  /* Foreground content color to use on secondary color */
          
          "accent":         "#6AB33E",  /* Accent color */
          "accent-focus":   "#539937",  /* Accent color - focused */
          "accent-content": "#FFFFFF",  /* Foreground content color to use on accent color */
          
          "neutral":         "#3D4451",  /* Neutral color */
          "neutral-focus":   "#2A2E37",  /* Neutral color - focused */
          "neutral-content": "#FFFFFF",  /* Foreground content color to use on neutral color */
          
          "base-100":     "#FFFFFF",  /* Base color of page, used for blank backgrounds */
          "base-200":     "#F9FAFB",  /* Base color, a little darker */
          "base-300":     "#D1D5DB",  /* Base color, even more darker */
          "base-content": "#1F2937",  /* Foreground content color to use on base color */
          
          "info":    "#64798A",  /* Info */
          "success": "#09783B",  /* Success */
          "warning": "#FB923C",  /* Warning */
          "error":   "#EF4444"   /* Error */
        }
      }
    ]
  },
  plugins:  [
    require( "daisyui" )
  ]
}

// Config
module.exports = config
