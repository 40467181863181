import classNames                 from "classnames"
import { Argument as ClassValue } from "classnames"
import React, { FC }              from "react"

interface Props {
  className?: ClassValue
}

const defaultProps: Props = {
  className: null
}

const BlockText: FC<Props> = ( props ) => {
  
  return (
    
    <span className={ classNames( `block`, props.className ) }>{ props.children }</span>
  )
}

BlockText.defaultProps = defaultProps

export { BlockText }
