import React, { FC, useState }        from 'react';
import { BlockText }                  from "../../common/type/BlockText"
import { ClearStackBlock }            from "../../common/site/StackBlock"
import { DataVisualizationAnimation } from "./DataVisualizationAnimation"

interface Props {}

const defaultProps:Props = {}

const DataVisualizationContentBlock:FC<Props> = ( props ) => {
	
	return (
    
    <ClearStackBlock>
      <div className={ `grid grid-cols-2 md:grid-cols-[1fr_33%]` }>
        <div className={`relative z-0`}>
          
          <DataVisualizationAnimation />
          
        </div>
        <div className={`relative z-10 text-right md:text-left`}>
          <h2 className={ `text-xl sm:text-2xl text-green-600` }>
            <BlockText>Custom <span className={ `text-2xl sm:text-3xl text-green-500` }>financial</span></BlockText>
            <BlockText><span className={ `text-slate-100 text-2xl sm:text-3xl` }>visualization apps</span> that reveal true <span className={ `text-2xl sm:text-3xl text-green-500` }>market insight</span>.</BlockText>
          </h2>
        </div>
      </div>
    </ClearStackBlock>
    
	);
};

DataVisualizationContentBlock.defaultProps = defaultProps;

export { DataVisualizationContentBlock }
