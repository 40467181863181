import React, { FC }          from "react"
import { BlockText }          from "../../common/type/BlockText"
import { ClearStackBlock }    from "../../common/site/StackBlock"
import { PlatformsAnimation } from "./PlatformsAnimation"

interface Props {}

const defaultProps: Props = {}

const PlatformsContentBlock: FC<Props> = ( props ) => {
  
  return (
    
    <ClearStackBlock>
      
      <div className={ `grid grid-cols-2 md:grid-cols-[1fr_33%]` }>
        <div className={ `relative z-0` }>
          
          <PlatformsAnimation />
        
        </div>
        <div className={ `relative z-10 text-right md:text-left` }>
          <h2 className={ `text-xl sm:text-2xl text-green-600` }>
            <BlockText>An algorithmic</BlockText>
            <BlockText><span className={ `text-2xl sm:text-3xl text-slate-100` }> planning platform</span> that helps bring <span className={ `text-green-500` }>biopharma<span className={ `hidden md:inline` }>ceutical</span> assets</span> from the <span className={ `text-green-500` }>lab</span> to the <span className={ `text-green-500` }>market</span>.</BlockText>
          </h2>
        </div>
      </div>
    
    
    </ClearStackBlock>
  
  )
}

PlatformsContentBlock.defaultProps = defaultProps

export { PlatformsContentBlock }
