import React, { FC, useState } from "react"
import { BlockText }           from "../../common/type/BlockText"
import { ClearStackBlock }     from "../../common/site/StackBlock"
import { MobileAppsAnimation } from "./MobileAppsAnimation"

interface Props {}

const defaultProps: Props = {}

const MobileAppsContentBlock: FC<Props> = ( props ) => {
  
  return (
    
    <ClearStackBlock>
      <div className={ `grid grid-cols-2 md:grid-cols-[1fr_33%]` }>
        <div>
          
          <MobileAppsAnimation />
          
        </div>
        <div className={ "text-right" }>
          <h2 className={ `text-xl sm:text-2xl text-green-600` }>
            <BlockText>A <span className={ `text-2xl sm:text-3xl text-green-500 inline-block` }>workforce-focused</span></BlockText>
            <BlockText><span className={ `text-slate-100 text-2xl sm:text-3xl` }>mobile app</span> </BlockText>
            <BlockText>that enhances staff <span className={ `text-2xl sm:text-3xl text-green-500` }>coordination & scheduling</span>.</BlockText>
          </h2>
        </div>
      </div>
    </ClearStackBlock>
  
  )
}

MobileAppsContentBlock.defaultProps = defaultProps

export { MobileAppsContentBlock }
