import React, { FC, useState } from "react"
import { ScrollObserver }      from "../../common/site/ScrollObserver"

interface Props {}

const defaultProps: Props = {}

const PlatformsAnimation: FC<Props> = ( props ) => {
  
  return (
    
    <ScrollObserver elementId={ "platforms-animation" } options={ {
      onScrollIntersectClassname: "activated",
      rootMargin:                 "0px 0px -15% 0px"
    } }>
      <div className={ "animation-container scale-50 md:scale-100" }>
        <div className={ "circle-a" } />
        <div className={ "circle-b" } />
        <div className={ "circle-c" } />
        <div className={ "dot-a" } />
        <div className={ "dot-b" } />
        <div className={ "dot-c" } />
        <div className={ "dot-d" } />
      </div>
    </ScrollObserver>
  )
}

PlatformsAnimation.defaultProps = defaultProps

export { PlatformsAnimation }
