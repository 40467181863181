import _                                  from "underscore"
import classNames                         from "classnames"
import React, { FC, ReactNode, useState } from "react"
import { ScrollObserver }                 from "../site/ScrollObserver"
import { ClearStackBlock }                from "../site/StackBlock"
import { ScrollTriggerOptions }           from "../../../hooks/useScrollObserver"

interface Props {
  color?: "obsidian" | "slate-500" | "slate-800" | "green-500" | "green-700"
  alignment?: "l" | "r",
  opacity?: number,
  width?: number,
  height?: number,
  offsetX?: string,
  offsetY?: number,
  offsetYAdjust?: string,
  animateLong?: boolean,
  shadowed?: boolean,
}

const defaultProps: Props = {
  color:         "slate-800",
  alignment:     "l",
  opacity:       0.4,
  width:         750,
  height:        90,
  offsetX:       "0px",
  offsetY:       0,
  offsetYAdjust: null,
  animateLong:   true,
  shadowed:      false
}

const DecoBlock: FC<Props> = ( props ) => {
  
  return (
    <div className={ classNames(
      `deco-block-container deco-block-alignment-${ props.alignment }`,
      {
        "deco-block-animate-long": props.animateLong,
        "shadowed":                props.shadowed
      } ) }>
      <div className={ `deco-block absolute bg-${ props.color } origin-center` }
           style={ {
             height:       props.height,
             width:        props.width,
             opacity:      props.opacity,
             marginBottom: -1 * props.height,
             ...( props.alignment == "l" ? { left: 0 } : { right: 0 } ),
             transform:    [
                             `rotate(15deg)`,
                             `translateY(${ props.offsetY }px)`,
                             `translateX(${ props.offsetX })`
                           ].join( " " )
           } } />
    </div>
  )
}

const DecoBlockScrollObserverContainer: FC<{
  id: string,
  prependedContent?: ReactNode,
  appendedContent?: ReactNode,
  elevateAboveContent?: boolean,
  scrollObserverOptions?: ScrollTriggerOptions,
}> = ( props ) => {
  
  return (
    <div className={ `relative` }>
      { props.prependedContent && (
        <div className={ `relative z-10` }>
          { props.prependedContent }
        </div>
      ) }
      <div className={ `relative ${ props.elevateAboveContent ? `z-20` : `z-0` } max-w-screen-sm m-auto` }>
        <ScrollObserver elementId={ props.id } options={ {
          onScrollIntersectClassname: "activated",
          ...( props.scrollObserverOptions ?? {} )
        } }>
          { props.children }
        </ScrollObserver>
      </div>
      { props.appendedContent && (
        <div className={ `relative z-10` }>
          { props.appendedContent }
        </div>
      ) }
    </div>
  )
}

DecoBlock.defaultProps = defaultProps

export { DecoBlock, DecoBlockScrollObserverContainer }
