import React, { FC, useState } from "react"
import { ScrollObserver }      from "../../common/site/ScrollObserver"

interface Props {}

const defaultProps: Props = {}

const DataVisualizationAnimation: FC<Props> = ( props ) => {
  
  return (
    
    <ScrollObserver elementId={ "data-visualization-animation" } options={ {
      onScrollIntersectClassname: "activated",
      rootMargin:                 "0px 0px -15% 0px"
    } }>
      <div className={ "animation-container scale-50 md:scale-100 relative left-[20%] md:left-0" }>
        <div className={ "rect-a" } />
        <div className={ "rect-b" } />
        <div className={ "rect-c" } />
        <div className={ "rect-d" } />
        <div className={ "rect-e" } />
        <div className={ "rect-f" } />
      </div>
    </ScrollObserver>
  )
}

DataVisualizationAnimation.defaultProps = defaultProps

export { DataVisualizationAnimation }
