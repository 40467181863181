// Adapted from https://css-tricks.com/scroll-triggered-animation-vanilla-javascript/
import _ from "underscore"

export interface ScrollTriggerOptions extends IntersectionObserverInit {
  onScrollIntersect?: ( el: Element, unobserveMethod: () => void, entry: IntersectionObserverEntry ) => void,
  onScrollIntersectClassname?: string | string[],
  removeClassnameOnNonIntersection?: boolean,
  unobserveOnScrollIntersect?: boolean,
}

function useScrollObserver(){
  
  function scrollTrigger( selector: string, options: ScrollTriggerOptions = {} ){
    
    let els      = document.querySelectorAll( selector ),
        elements = Array.from( els )
    
    elements.forEach( el => {
      
      addObserver( el, options )
      
    } )
  }
  
  function addObserver( el: Element, options: ScrollTriggerOptions ){
    
    const activatedClass: string[] = _.chain( [ options.onScrollIntersectClassname ?? "scrollObserverActivated" ] )
                                      .flatten()
                                      .map( c => c.trim() )
                                      .value()
    
    const { unobserveOnScrollIntersect = true } = options
    
    // Check if `IntersectionObserver` is supported
    if( !( "IntersectionObserver" in window ) ){
      
      // Simple fallback
      // The animation/callback will be called immediately so
      // the scroll animation doesn't happen on unsupported browsers
      if( options.onScrollIntersect ){
        
        options.onScrollIntersect( el, () => {}, null )
        
      } else {
        
        el.classList.add( activatedClass as any )
        
      }
      
      // We don't need to execute the rest of the code
      return
    }
    
    // Setup IntersectionObserver
    let observer = new IntersectionObserver( ( entries, observer ) => {
      
      entries.forEach( entry => {
        
        if( entry.isIntersecting ){
          
          if( options.onScrollIntersect ){
            
            options.onScrollIntersect( el, () => observer.unobserve( entry.target ), entry )
            
          } else {
            
            entry.target.classList.add( activatedClass as any )
            
            if( unobserveOnScrollIntersect ){
              
              observer.unobserve( entry.target )
            }
          }
          
        } else {
          
          if( !unobserveOnScrollIntersect && options.removeClassnameOnNonIntersection ){
            
            if( entry.target.id === "mission-statement-text" ){
              debugger;
            }
            
            entry.target.classList.remove( activatedClass as any )
          }
        }
        
      } )
    }, options )
    
    observer.observe( el )
  }
  
  return {
    scrollTrigger
  }
}

export { useScrollObserver }

/*
// Example [ basic ]
scrollTrigger( ".intro-text" )

// Example [ positional condition ]
scrollTrigger( ".scroll-reveal", {
  rootMargin: "-200px"
} )

// Example [ with callback ]
scrollTrigger( ".loader", {
  rootMargin: "-200px",
  onScrollIntersect:         function( el ){
    el.innerText = "Loading..."
    setTimeout( () => {
      el.innerText = "Task Complete!"
    }, 1000 )
  }
} )*/
