import classNames    from "classnames"
import React, { FC } from "react"

import TailwindConfig      from "../../../../tailwind.config"
import { BlockText }       from "../../common/type/BlockText"
import { ClearStackBlock } from "../../common/site/StackBlock"

interface Props {}

const defaultProps: Props = {}

export const SCROLL_MARKER_ID    = "intro-content"
export const SCROLL_CONTAINER_ID = "scroller-content"
export const SCROLL_ITEM_PREFIX  = "capability"

const containerMinHeight = 200

const MissionStatementContentBlock: FC<Props> = ( props ) => {
  
  let slateGlassValue            = TailwindConfig.theme.extend.colors.slateGlass,
      slateGlassValueTransparent = TailwindConfig.theme.extend.colors.slateGlassTransparent
  
  return (
    <div className={ `relative` }>
      
      {/* Fader gradients */ }
      <div className={ `absolute z-0 opacity-[0.95] top-[-300px] inset-x-[-300px] md:inset-x-0 h-[600px] md:h-[800px]` } style={ { backgroundImage: `radial-gradient(ellipse at center top,${ slateGlassValue } 0%, ${ slateGlassValue } 42%, ${ slateGlassValueTransparent } 70%, ${ slateGlassValueTransparent } 80%)` } } />
      <div className={ `absolute z-0 opacity-[0.95] top-0 inset-x-0 h-[300px] md:h-[400px] bg-gradient-to-b from-slateGlass` } />
      <div className={ `absolute z-0 opacity-[0.95] bottom-full inset-x-0 h-[300px] bg-slateGlass` } />
      
      <ClearStackBlock className={ `z-1relative sm:p-20 md:pb-5` }
                       style={ {
                         maxHeight: containerMinHeight,
                         boxSizing: "content-box"
                       } }>
        
        {/* Text Content */ }
        <div id={ "mission-statement-text" } className={ `relative z-1 uppercase tracking-tighter animate-fade-in` }>
          
          <BlockText className={ `font-light text-4xl md:text-6xl text-slate-100` }>We Build</BlockText>
          
          <div className={ `capabilities-scroller-container` }>
            
            <BlockText className={ classNames(
              `font-semibold text-5xl md:text-7xl`,
              `capabilities-scroller-item`,
              `text-amber-400`
            ) }>
              
              <>Products <span className={ `text-xl md:text-7xl relative top-[-20px] md:top-0` }>with</span> Purpose</>
            
            </BlockText>
          
          </div>
        
        </div>
      
      </ClearStackBlock>
    
    </div>
  )
}

MissionStatementContentBlock.defaultProps = defaultProps

export { MissionStatementContentBlock }
