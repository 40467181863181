import React, { FC, useState }                         from 'react';
import { BlockText }                                   from "../../common/type/BlockText"
import { ClearStackBlock }                             from "../../common/site/StackBlock"
import { DecoBlock, DecoBlockScrollObserverContainer } from "../../common/display/DecoBlock"
import { ProjectManagementAnimation }                  from "./ProjectManagementAnimation"

interface Props {}

const defaultProps:Props = {}

const ProjectManagementContentBlock:FC<Props> = ( props ) => {
	
	return (
    
    <ClearStackBlock>
  
      <div className={ `grid grid-cols-2 md:grid-cols-[33%_1fr]` }>
        <div>
          <h2 className={ `text-xl sm:text-2xl text-green-600` }>
            <BlockText>A suite of</BlockText>
            <BlockText className={ `text-slate-100 text-2xl sm:text-3xl` }>project management </BlockText>
            <BlockText>tools for the <span className={ `text-2xl sm:text-3xl text-green-500` }>real estate</span> industry.</BlockText>
          </h2>
        </div>
        <div>
      
          <ProjectManagementAnimation />
    
        </div>
      </div>
      
    </ClearStackBlock>
    
	);
};

ProjectManagementContentBlock.defaultProps = defaultProps;

export { ProjectManagementContentBlock }
